var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.transporterMtrs != null
    ? _c(
        "section",
        { staticClass: "transporter-mtrs" },
        [
          _c(
            "v-card",
            { staticClass: "elevation-10 pa-4" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-space-between": "" } },
                [
                  _c("v-flex", { staticClass: "header" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v("\n          Olá,\n          "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.currentUser.person.name) +
                            " (" +
                            _vm._s(_vm.cpf_cnpj) +
                            ")"
                        ),
                      ]),
                      _vm._v(
                        ".\n          Você tem " +
                          _vm._s(_vm.cardsLength.all) +
                          " MTRs:\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  attrs: { "align-center": "", "justify-center": "", row: "" },
                },
                [
                  _c("filter-cards", {
                    attrs: { cards: _vm.cards, activeStatus: _vm.activeStatus },
                    on: { changeActive: _vm.toggleActive },
                  }),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-text-field", {
                        staticClass: "custom-field",
                        attrs: {
                          box: "",
                          "append-outer-icon": "mdi-magnify",
                          label: "Nome, razão social, CPF ou CNPJ",
                          "append-icon": "la-search",
                          loading: _vm.loading,
                        },
                        on: { input: _vm.searchMtrs },
                        model: {
                          value: _vm.searchValue,
                          callback: function ($$v) {
                            _vm.searchValue = $$v
                          },
                          expression: "searchValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isFetchingMtrs
            ? _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("sort-mtrs", {
                    attrs: { sortBy: _vm.sortBy },
                    on: { selectOrder: _vm.sortList },
                  }),
                  _vm._l(_vm.mtrsFiltereds, function (mtr, index) {
                    return !_vm.isFetchingMtrs
                      ? _c(
                          "v-layout",
                          {
                            key: index,
                            staticClass: "mb-4",
                            attrs: { column: "", "justify-space-between": "" },
                          },
                          [
                            _c(
                              "v-toolbar",
                              {
                                attrs: {
                                  dark: "",
                                  color: "green darken-4",
                                  flat: "",
                                  dense: "",
                                  cad: "",
                                },
                              },
                              [
                                _c(
                                  "v-toolbar-title",
                                  { staticClass: "subheading" },
                                  [
                                    _c("small", [_vm._v("MTR")]),
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(mtr.number) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-toolbar-items",
                                  [
                                    mtr.status === "to_receive"
                                      ? _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _vm._v(
                                              "\n            Salvo em: " +
                                                _vm._s(
                                                  _vm.format(
                                                    mtr.created_at,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    mtr.status ===
                                    "received_by_temporary_storage"
                                      ? _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _vm._v(
                                              "\n            Armazenamento Temporário:\n            " +
                                                _vm._s(
                                                  _vm.format(
                                                    mtr.received_at,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : mtr.status === "received" ||
                                        (mtr.status === "certified" &&
                                          mtr.cdf_emitted_at !== null)
                                      ? _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _vm._v(
                                              "\n            Recebido em: " +
                                                _vm._s(
                                                  _vm.format(
                                                    mtr.received_at,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { "align-center": "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { column: "" } },
                                              [
                                                _c("strong", [
                                                  _vm._v("Gerador"),
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._.get(
                                                        mtr,
                                                        "generator_data.name"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._.has(
                                                  mtr,
                                                  "generator_data.cpf_cnpj"
                                                )
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          "align-center": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  mtr
                                                                    .generator_data
                                                                    .cpf_cnpj
                                                                    .length ===
                                                                    14
                                                                    ? "CNPJ"
                                                                    : "CPF"
                                                                ) +
                                                                ":  \n                  "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.formatCpfCnpj(
                                                                    mtr
                                                                      .generator_data
                                                                      .cpf_cnpj
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { column: "" } },
                                              [
                                                _c("strong", [
                                                  _vm._v("Destinador"),
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._.get(
                                                        mtr,
                                                        "receiver_data.name"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._.has(
                                                  mtr,
                                                  "receiver_data.cpf_cnpj"
                                                )
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          "align-center": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  mtr
                                                                    .receiver_data
                                                                    .cpf_cnpj
                                                                    .length ===
                                                                    14
                                                                    ? "CNPJ"
                                                                    : "CPF"
                                                                ) +
                                                                ":  \n                  "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.formatCpfCnpj(
                                                                    mtr
                                                                      .receiver_data
                                                                      .cpf_cnpj
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs2: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: { "justify-center": "" },
                                              },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      color:
                                                        _vm.chipStatusColor(
                                                          mtr
                                                        ),
                                                      "text-color": "white",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.translatedStatus(
                                                            mtr.status
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-capitalize",
                                            attrs: {
                                              round: "",
                                              small: "",
                                              outline: "",
                                              color: "grey darken-1",
                                              loading:
                                                _vm.downloadingMtrReportId ===
                                                mtr.id,
                                              disabled: _vm.isRequesting,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showMtrPdf(mtr.id)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { left: "", size: "16" },
                                              },
                                              [_vm._v("mdi-file-document")]
                                            ),
                                            _vm._v(
                                              "\n              Visualizar MTR\n            "
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.isToReceive(mtr.status) &&
                                        mtr.status !== "rejected"
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "text-capitalize",
                                                attrs: {
                                                  round: "",
                                                  small: "",
                                                  outline: "",
                                                  color: "grey darken-1",
                                                  disabled: _vm.isRequesting,
                                                  loading:
                                                    _vm.downloadingMtrReceivementReportId ===
                                                    mtr.id,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadMtrReceivementReport(
                                                      mtr.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      size: "16",
                                                    },
                                                  },
                                                  [_vm._v("mdi-file-document")]
                                                ),
                                                _vm._v(
                                                  "\n              Visualizar Relatório de Recebimento\n            "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm.pagination.totalPages >= 1 && !_vm.isFetchingMtrs
                    ? _c(
                        "v-layout",
                        { attrs: { "justify-center": "", "align-center": "" } },
                        [
                          _c("v-pagination", {
                            staticClass: "align-self-center",
                            attrs: {
                              length: _vm.pagination.totalPages,
                              circle: "",
                            },
                            on: { input: _vm.fetchTransporterMtrs },
                            model: {
                              value: _vm.pagination.activePage,
                              callback: function ($$v) {
                                _vm.$set(_vm.pagination, "activePage", $$v)
                              },
                              expression: "pagination.activePage",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mtrsFiltereds.length === 0
                    ? _c(
                        "empty-content",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mb-2",
                              attrs: { slot: "content", size: "56" },
                              slot: "content",
                            },
                            [_vm._v("mdi-inbox")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "headline grey--text text--darken-1",
                              attrs: { slot: "message" },
                              slot: "message",
                            },
                            [
                              _vm._v(
                                "\n        Não há MTR's para exibir\n      "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "fill-height": "",
                        "align-content-center": "",
                        "justify-center": "",
                      },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }