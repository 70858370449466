var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "v-container",
        { attrs: { slot: "list", fluid: "" }, slot: "list" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "list-tabs",
              attrs: { "slider-color": "white", flat: "" },
            },
            [
              _c("v-tab", [_c("strong", [_vm._v("Manifestos")])]),
              _c("v-tab-item", [_c("transporter-mtrs")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }